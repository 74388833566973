<template>
  <div class="content-box">
    <b-form @submit.prevent="onSubmit">
      <b-form-group
        :class="{ row, error: v$.administrator.givenName.$errors.length }"
        :label="$t('forms.givenName')"
        label-for="givenName"
        label-cols-md="4"
        content-cols-lg="8"
      >
        <b-form-input v-model="administrator.givenName" id="givenName" />
        <div
          class="input-errors"
          v-for="error of v$.administrator.givenName.$errors"
          :key="error.$uid"
        >
          <small class="text-danger">{{ error.$message }}</small>
        </div>
      </b-form-group>
      <b-form-group
        :class="{ row, error: v$.administrator.familyName.$errors.length }"
        :label="$t('forms.familyName')"
        label-for="familyName"
        label-cols-md="4"
        content-cols-lg="8"
      >
        <b-form-input v-model="administrator.familyName" id="familyName" />
        <div
          class="input-errors"
          v-for="error of v$.administrator.familyName.$errors"
          :key="error.$uid"
        >
          <small class="text-danger">{{ error.$message }}</small>
        </div>
      </b-form-group>
      <b-form-group
        :class="{ row, error: v$.administrator.email.$errors.length }"
        :label="$t('forms.email')"
        label-for="email"
        label-cols-md="4"
        content-cols-lg="8"
      >
        <b-form-input v-model="administrator.email" id="email" />
        <div
            class="input-errors"
            v-for="error of v$.administrator.email.$errors"
            :key="error.$uid"
        >
          <small class="text-danger">{{ error.$message }}</small>
        </div>
      </b-form-group>
      <b-form-group
          class="row"
          :label="$t('forms.password')"
          label-for="password"
          label-cols-md="4"
          content-cols-lg="8"
      >
        <b-form-input
            v-model="administrator.plainPassword"
            id="password"
        />
      </b-form-group>
    </b-form>
  </div>
</template>

<script>
import useVuelidate from "@vuelidate/core";
import { required } from "@vuelidate/validators";
import ResourceUtils from "@/mixins/ResourceUtils";
import ListUtils from "@/mixins/ListUtils";
import "vue3-date-time-picker/dist/main.css";

export default {
  props: {
    administratorObject: { Object, required: true },
    action: { type: String, default: null },
  },
  mixins: [ResourceUtils, ListUtils],
  setup: () => ({ v$: useVuelidate() }),
  validations() {
    return {
      administrator: {
        givenName: { required },
        familyName: { required },
        email: { required },
      },
    };
  },
  data() {
    return {
      administrator: this.administratorObject,
    };
  },
  watch: {
    action() {
      this.onSubmit();
    },
  },
  methods: {
    async onSubmit() {
      const isValid = await this.v$.$validate();
      if (isValid) {
        const body = this.administrator
        if (this.action === "add") {
          this.create(
            this.$Users,
            body,
            this.$t("messages.administratorCreated"),
            null,
            this.success,
            this.error
          );
        }
        if (this.action === "update") {
          this.$emit("clearAction");
          this.update(
            this.$Users,
            body.id,
            body,
            this.$t("messages.administratorUpdated"),
            null,
            null,
            this.error
          );
        }
      } else {
        this.$emit("clearAction");
      }
    },
    success() {
      this.$router.push(`/administrators`);
    },
    error() {
      this.$emit("clearAction");
    },
  },
};
</script>

<style lang="scss">
@import "vue-select/src/scss/vue-select.scss";
</style>

